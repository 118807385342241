import { DatePicker, Flexbox, Typography } from 'components';
import classNames from 'classnames/bind';
import styles from '../styles.module.scss'
import { CalendarPresetsKeys } from 'utils/types';
import { useEffect, useState } from 'react';
import {
    getQuarterStartDate,
    getQuarterEndDate,
    getPreviousQuarterEndDate,
    getPreviousQuarterStartDate,
    getNextYearEndDate,
    getCurrentYearEndDate,
    getCurrentYearStartDate,
    getNextQuarterEndDate,
    getNextQuarterStartDate,
    getNextMonthStartDate,
    getCurrentMonthEndDate,
    getCurrentMonthStartDate,
    getNextMonthEndDate,
    getNextYearStartDate,
    getPreviousMonthStartDate,
    getPreviousMonthEndDate
} from 'utils/date';
import { Preset } from 'components/Datepicker';
import {
    endDateSelector,
    setEndDate,
    startDateSelector,
    setStartDate,
    executionStartDateSelector,
    setExecutionStartDate,
    setCalendarSelect,
    calendarSelectSelector,
    setShouldUpdate
} from 'store/roadmapMetrics-slice';
import { useDispatch, useSelector } from 'react-redux';

const classes = classNames.bind(styles);

const findMatchingPreset = (start: Date, end: Date): CalendarPresetsKeys | undefined => {
    const dateChecks = {
        [CalendarPresetsKeys.thisMonth]: [getCurrentMonthStartDate(), getCurrentMonthEndDate()],
        [CalendarPresetsKeys.nextMonth]: [getNextMonthStartDate(), getNextMonthEndDate()],
        [CalendarPresetsKeys.lastMonth]: [getPreviousMonthStartDate(), getPreviousMonthEndDate()],
        [CalendarPresetsKeys.thisQuarter]: [getQuarterStartDate(), getQuarterEndDate()],
        [CalendarPresetsKeys.nextQuarter]: [getNextQuarterStartDate(), getNextQuarterEndDate()],
        [CalendarPresetsKeys.lastQuarter]: [getPreviousQuarterStartDate(), getPreviousQuarterEndDate()],
        [CalendarPresetsKeys.thisYear]: [getCurrentYearStartDate(), getCurrentYearEndDate()],
        [CalendarPresetsKeys.nextYear]: [getNextYearStartDate(), getNextYearEndDate()]
    };

    for (const [preset, [presetStart, presetEnd]] of Object.entries(dateChecks)) {
        if (start.getTime() === presetStart.getTime() &&
            end.getTime() === presetEnd.getTime()) {
            return preset as CalendarPresetsKeys;
        }
    }

    return undefined;
};

const Header = () => {
    const dispatch = useDispatch();

    const startDate = useSelector(startDateSelector);
    const endDate = useSelector(endDateSelector);
    const calendarSelect = useSelector(calendarSelectSelector);
    const executionStartDate = useSelector(executionStartDateSelector);

    const [presets, setPresets] = useState<Preset[]>([
        { id: CalendarPresetsKeys.lastQuarter, title: 'Last quarter' },
        { id: CalendarPresetsKeys.lastMonth, title: 'Last month' },
        { id: CalendarPresetsKeys.thisMonth, title: 'This month' },
        { id: CalendarPresetsKeys.nextMonth, title: 'Next month' },
        { id: CalendarPresetsKeys.thisQuarter, title: 'This quarter' },
        { id: CalendarPresetsKeys.nextQuarter, title: 'Next quarter' },
        { id: CalendarPresetsKeys.thisYear, title: 'This year' },
        { id: CalendarPresetsKeys.nextYear, title: 'Next year' },
    ])

    const onDateChange = ([startDate, endDate]: [Date | null, Date | null]) => {
        dispatch(setCalendarSelect(undefined))
        dispatch(setStartDate(startDate));
        dispatch(setEndDate(endDate));
        dispatch(setShouldUpdate(true));
    };

    const selectCalendarRange = (id: string) => {
        dispatch(setCalendarSelect(id))
        dispatch(setShouldUpdate(true));
    }

    useEffect(() => {
        if (executionStartDate) {
            return;
        } else if (startDate && endDate && !calendarSelect) {
            const matchingPreset = findMatchingPreset(startDate, endDate);
            if (matchingPreset) {
                dispatch(setCalendarSelect(matchingPreset));
            }
        }
    }, []);

    useEffect(() => {
        dispatch(setShouldUpdate(false));
        if (calendarSelect === CalendarPresetsKeys.thisMonth) {
            dispatch(setStartDate(getCurrentMonthStartDate()));
            dispatch(setEndDate(getCurrentMonthEndDate()));
        } else if (calendarSelect === CalendarPresetsKeys.nextMonth) {
            dispatch(setStartDate(getNextMonthStartDate()));
            dispatch(setEndDate(getNextMonthEndDate()));
        } else if (calendarSelect === CalendarPresetsKeys.thisQuarter) {
            dispatch(setStartDate(getQuarterStartDate()));
            dispatch(setEndDate(getQuarterEndDate()));
        } else if (calendarSelect === CalendarPresetsKeys.nextQuarter) {
            dispatch(setStartDate(getNextQuarterStartDate()));
            dispatch(setEndDate(getNextQuarterEndDate()));
        } else if (calendarSelect === CalendarPresetsKeys.thisYear) {
            dispatch(setStartDate(getCurrentYearStartDate()));
            dispatch(setEndDate(getCurrentYearEndDate()));
        } else if (calendarSelect === CalendarPresetsKeys.nextYear) {
            dispatch(setStartDate(getNextYearStartDate()));
            dispatch(setEndDate(getNextYearEndDate()));
        } else if (calendarSelect === CalendarPresetsKeys.lastMonth) {
            dispatch(setStartDate(getPreviousMonthStartDate()));
            dispatch(setEndDate(getPreviousMonthEndDate()));
        } else if (calendarSelect === CalendarPresetsKeys.lastQuarter) {
            dispatch(setStartDate(getPreviousQuarterStartDate()));
            dispatch(setEndDate(getPreviousQuarterEndDate()));
        }

        setPresets((current) => {
            return current.map(p => ({ ...p, isSelected: p.id === calendarSelect }))
        });
    }, [calendarSelect])

    return (
        <Flexbox alignEnd justifyBetween fullWidth wrap className={classes('header', 'gap-4')}>
            <Flexbox vertical className={classes('gap-4')}>
                <Typography className={classes('title')}>Roadmap Metrics</Typography>
                <Flexbox align justify className={classes('datePickerBox', 'gap-2')}>
                    <Flexbox className={classes('datePickerBox-label')}>
                        Execution start date
                    </Flexbox>
                    <DatePicker
                        placeholderText='Execution start date'
                        onChange={(date) => {
                            dispatch(setExecutionStartDate(date as Date | null));
                            dispatch(setShouldUpdate(true));
                        }}
                        selected={executionStartDate}
                        isClearable
                        fullWidth
                    />
                </Flexbox>
            </Flexbox>
            <Flexbox wrap vertical alignEnd justify className={classes('gap-2')}>
                <Flexbox alignEnd justify className={classes('datePickerBox')}>
                    <DatePicker
                        onChange={onDateChange}
                        startDate={startDate}
                        endDate={endDate}
                        presets={{
                            onPresetSelect: selectCalendarRange,
                            presets,
                        }}
                        monthsShown={2}
                        selectsRange
                        type='button'
                        dateFormat="MM.dd.yy"
                    />
                </Flexbox>
            </Flexbox>
        </Flexbox>
    )
}

export default Header