import { useRef, useState, KeyboardEvent, useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import Markdown from 'components/Markdown';

import { Box, CommonButton, CustomTyphography, Flexbox, IconButton, Loader, Snackbar, TextArea, Tooltip, } from 'components';
import { ChatIcon, CloseIcon, Logo, MenuOpenIcon, SendIcon } from 'components/icons';
import AMAMessage from './components/AMAMessage';
import MessageSidePanel from './components/MessageSidePanel';
import MockupPanel from './components/MessageSidePanel/MockupPanel';
import ResizableDivider from 'components/ResizableDivider';
import PRDPreviewPanel from './components/MessageSidePanel/PRDPreviewPanel';
import AIRefineDialog from './components/AIRefineDialog';
import FeedbackDialog from './components/FeedbackDialog';

import { getKBItemFromAMA, getUserLatestSession, getUserSession, sendFeedback, sendMessage } from './assistant.api';

import { currentSessionLoadingSelector, getRelevantReferences, replaceMessages, updateMessages } from 'store/assistant-slice';
import { userSelector } from 'store/user';

import { capitalize } from 'utils/string';
import { getFromLocalStorage } from 'utils/localStorage';
import { AIAnswerTypes, AMAReference, AMAResponseTypes, AMAUserFeedback, AssistantMessage, Message } from 'utils/types';

import classNames from 'classnames/bind';
import styles from './assistant.module.scss';

const classes = classNames.bind(styles);

const chatActionButtons = [
    {
        label: 'Create a PRD',
        message: 'Create a new PRD ',
    },
    {
        label: 'Create a Mockup',
        message: 'Make a new Mockup ',
    },
    {
        label: 'Search Knowledge Base',
        message: 'Search in Knowledge Base ',
    }
]

interface SessionProps {
    sessionId: string
    messages: Message[]
    openHistorySidebar: boolean,
    onToggleHistorySidebar: () => void,
    onStartNewSession: () => void,
    sessionsLength: number
    setIsSessionSwitchDisabled: (disabled: boolean) => void
}

type MessageSidePanelBase = {
    open: boolean;
    id?: string | number;
    manual?: boolean;
    streamingId?: string | number;
};

export type PRDPreviewSidePanel = MessageSidePanelBase & {
    content?: any | { [key: string]: string };
    messageType?: AIAnswerTypes.PRD_PREVIEW | AIAnswerTypes.PRD_PREVIEW_STREAM;
    currentSection?: string;
};

export type MockupSidePanel = MessageSidePanelBase & {
    content?: any;
    messageType?: AIAnswerTypes.MOCKUP;
};

export type MessageSidePanel =
    | PRDPreviewSidePanel
    | MockupSidePanel

export function isPRDPreviewSidePanel(panel: MessageSidePanel): panel is PRDPreviewSidePanel {
    return panel.messageType === AIAnswerTypes.PRD_PREVIEW || panel.messageType === AIAnswerTypes.PRD_PREVIEW_STREAM;
}

export function isMockupSidePanel(panel: MessageSidePanel): panel is MockupSidePanel {
    return panel.messageType === AIAnswerTypes.MOCKUP;
}

const Session: FC<SessionProps> = ({
    sessionId,
    messages,
    openHistorySidebar,
    onToggleHistorySidebar,
    onStartNewSession,
    sessionsLength,
    setIsSessionSwitchDisabled
}) => {
    const dispatch = useDispatch();

    const gridContainerRef = useRef<HTMLDivElement>(null);
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const lastSelectedSessionId = getFromLocalStorage('sessionId');
    const user = useSelector(userSelector);
    const currentSessionLoading = useSelector(currentSessionLoadingSelector);

    const [question, setQuestion] = useState('');
    const [leftWidth, setLeftWidth] = useState(50);
    const [messageSidePanel, setMessageSidePanel] = useState<PRDPreviewSidePanel | MockupSidePanel>({ open: false })
    const [isLoading, setIsLoading] = useState(false);
    const [assistantStatus, setAssistantStatus] = useState<null | string>(null);
    const [streamedText, setStreamedText] = useState('');
    const [feedbackStatus, setFeedbackStatus] = useState<'initial' | 'pending' | 'success' | null>(null)
    const [aiRefineDialog, setAiRefineDialog] = useState<{ open: boolean, type?: 'prd' | 'mockup', field?: string, content?: string | Record<string, any> }>({ open: false })
    const [refinedContent, setRefinedContent] = useState<string>('')
    const [latestMessageId, setLatestMessageId] = useState<number | null>(null)
    const [feedback, setFeedback] = useState<AMAUserFeedback | null>(null)
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('Sorry, An error occurred. Please try again later!');

    const onSendMessage = async (refinementType?: 'prd' | 'mockup', prompt?: string) => {
        if (sessionId) {
            setFeedbackStatus('initial');

            let prdStreamingValue: any = {}
            let userPrompt: string = question
            let context;

            // Final message Values
            let refinement: boolean = false
            let answerType: AIAnswerTypes = AIAnswerTypes.TEXT
            let generatedText = '';
            let jsonResponse: any = {}
            let mockupStreamingValue: string = ''
            let references: AMAReference[] = []
            const newDiscussionId = uuid();

            if (refinementType) {
                context = { type: 'refine', object_type: aiRefineDialog.type, content: aiRefineDialog.content }
            }

            if (refinementType === 'prd' && !!aiRefineDialog.field) {
                // Refined from PRD Section
                userPrompt = `Make the following changes to the field "${capitalize(aiRefineDialog.field || '')}":\n${capitalize(refinedContent)}`
            } else if (!!refinedContent) {
                // Refined from Mockup/PRD
                userPrompt = capitalize(refinedContent)
            } else if (!!prompt) {
                // Refine PRD Confirmation
                userPrompt = prompt
            }

            const newMessages: Message[] = [
                {
                    from: 'user',
                    text: userPrompt,
                    references: [],
                    id: newDiscussionId
                },
                ...messages
            ];

            dispatch(replaceMessages(newMessages));
            setQuestion('');
            setRefinedContent('')
            setIsLoading(true)
            setAiRefineDialog({ open: false })

            setMessageSidePanel(prev => ({
                ...prev,
                // Each time when user asks something we should reset manual value
                manual: false,
            }))

            dispatch(
                sendMessage(
                    userPrompt,
                    sessionId,
                    (value: any, type?: AMAResponseTypes) => {
                        if (type === AMAResponseTypes.LOG) {
                            setAssistantStatus(value as string)
                        } else if (type === AMAResponseTypes.METADATA) {
                            setLatestMessageId(value.message_id)
                            references = Array.isArray(value.references) ? getRelevantReferences(value.references) : []
                        } else if (type === AMAResponseTypes.FINISH) {
                            setIsLoading(false)
                            setAssistantStatus(null)

                            dispatch(updateMessages([
                                {
                                    id: newDiscussionId,
                                    from: 'assistant',
                                    text: {
                                        type: answerType,
                                        refinement,
                                        content: answerType === AIAnswerTypes.PRD_PREVIEW ? jsonResponse : answerType === AIAnswerTypes.MOCKUP ? mockupStreamingValue : generatedText.trim().replace('```', '')
                                    },
                                    references,
                                }
                            ]));

                            setMessageSidePanel(prev => ({
                                ...prev,
                                streamingId: undefined,
                            }))

                            mockupStreamingValue = ''
                            setStreamedText('');
                            return;
                        } else {
                            setAssistantStatus(value.type === AIAnswerTypes.PRD_PREVIEW_STREAM ? 'Preparing the PRD...' : null);
                            let text = '';
                            refinement = value.refinement;
                            answerType = value.type

                            if (value.type === AIAnswerTypes.TEXT) {
                                text = value.content;
                            } else if (value.type === AIAnswerTypes.PRD_PREVIEW) {
                                jsonResponse = value.content

                                // Automatically open Side Panel when PRD is ready
                                setMessageSidePanel(prev => ({
                                    open: true,
                                    manual: prev.manual,
                                    messageType: AIAnswerTypes.PRD_PREVIEW,
                                    id: newDiscussionId,
                                    content: jsonResponse,
                                    currentSection: messageSidePanel.manual && isPRDPreviewSidePanel(prev) && prev.currentSection ? prev.currentSection : undefined,
                                }))

                                // Reset prdStreamingValue when PRD is Ready
                                prdStreamingValue = {}
                            } else if (value.type === AIAnswerTypes.PRD_PREVIEW_STREAM) {
                                setIsLoading(false)

                                const [key, contentValue] = Object.entries(value.content)[0];

                                // To Prevent Title Generation as a separate section
                                prdStreamingValue = key === 'title'
                                    ? prdStreamingValue
                                    : {
                                        ...prdStreamingValue,
                                        [key]: prdStreamingValue ? ((prdStreamingValue[key] || '') + contentValue).replace('```html', '') : contentValue
                                    };

                                setMessageSidePanel(prev => {
                                    if (!prev.id || Object.keys(prdStreamingValue).some(key => key !== 'title') || (prev.id === newDiscussionId)) {

                                        return {
                                            open: true,
                                            id: newDiscussionId,
                                            manual: prev.manual,
                                            messageType: AIAnswerTypes.PRD_PREVIEW_STREAM,
                                            content: prdStreamingValue,
                                            currentSection: prev.manual && isPRDPreviewSidePanel(prev) ? prev.currentSection : key,
                                            streamingId: newDiscussionId,
                                        }
                                    } else {
                                        return prev
                                    }
                                })

                                const updatedMessages: Message[] = [
                                    {
                                        id: newDiscussionId,
                                        from: 'assistant',
                                        text: {
                                            type: AIAnswerTypes.PRD_PREVIEW_STREAM,
                                            content: prdStreamingValue,
                                            refinement: value.refinement
                                        },
                                        references,
                                    },
                                    ...newMessages
                                ];

                                dispatch(replaceMessages(updatedMessages));
                            } else if (value.type === AIAnswerTypes.MOCKUP) {
                                setIsLoading(false)
                                mockupStreamingValue = `${mockupStreamingValue}${value.content}`

                                setMessageSidePanel(prev => {
                                    // If side panel is closed,
                                    // or there is no mockupStreamingValue so it enters to this setState for the first time,
                                    // or the current panel and opened one are the same
                                    if (!prev.id || !mockupStreamingValue || (prev.id === newDiscussionId)) {
                                        return {
                                            open: true,
                                            id: newDiscussionId,
                                            manual: prev.manual,
                                            messageType: AIAnswerTypes.MOCKUP,
                                            streamingId: newDiscussionId,
                                            content: mockupStreamingValue,
                                        }
                                    } else {
                                        return {
                                            ...prev,
                                            streamingId: newDiscussionId,
                                        }
                                    }
                                })

                                const updatedMessages: Message[] = [
                                    {
                                        id: newDiscussionId,
                                        from: 'assistant',
                                        text: {
                                            type: AIAnswerTypes.MOCKUP,
                                            content: mockupStreamingValue,
                                            refinement: value.refinement
                                        },
                                        references,
                                    },
                                    ...newMessages
                                ];

                                dispatch(replaceMessages(updatedMessages));
                            }

                            generatedText = (generatedText + text).replace('```html', '')
                            setStreamedText(generatedText);
                        }
                    },
                    (error) => {
                        // Show appropriate Error message
                        if (error.message) {
                            setErrorMessage(error.message)
                        }

                        setStreamedText('');
                        setIsLoading(false)
                        setAssistantStatus(null)
                        setError(true)
                    },
                    context
                )
            );
        }
    };

    const onGetKBSource = async (kbId: number, kbItemIds: number[]) => {
        try {
            await dispatch(getKBItemFromAMA({ kbId, data: { items: kbItemIds } }))
        } catch (err) {
            console.log(err);
        }
    }

    const handleMessagePreview = (message: AssistantMessage, key?: string) => {
        setMessageSidePanel(prev => {
            let newPanel: MessageSidePanel = {
                open: true,
                id: message.id,
                manual: true,
                streamingId: prev.streamingId
            }

            if (key) {
                newPanel = {
                    ...newPanel,
                    messageType: message.text.type as (AIAnswerTypes.PRD_PREVIEW | AIAnswerTypes.PRD_PREVIEW_STREAM),
                    currentSection: key,
                    content: typeof message.text === 'object' && 'content' in message.text && typeof message.text.content === 'object' ? {
                        ...prev.content,
                        [key]: message.text.content[key]
                    } : {},
                    manual: message.text.type === AIAnswerTypes.PRD_PREVIEW_STREAM,
                }
            } else if (message.text.type === AIAnswerTypes.PRD_PREVIEW) {
                newPanel = {
                    ...newPanel,
                    messageType: AIAnswerTypes.PRD_PREVIEW,
                    content: (message.from === 'assistant' && 'content' in message.text ? message.text.content : undefined) as { [key: string]: string },
                }
            } else if (message.text.type === AIAnswerTypes.MOCKUP) {
                newPanel = {
                    ...newPanel,
                    messageType: AIAnswerTypes.MOCKUP,
                    content: message.text.content,
                }
            }

            return newPanel
        })
    }

    const onKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            onSendMessage();
        }
    };

    const handleRefineSection = (message: AssistantMessage) => {
        setAiRefineDialog(prev => ({ ...prev, open: true, type: 'prd', content: message.text?.content }))
    }

    const handleConfirmRefinedPRD = () => {
        onSendMessage(undefined, 'Show the full updated PRD')
    }

    const handleSendFeedback = (feedbackType: 'positive' | 'negative') => {
        setFeedback({ type: feedbackType, content: '' })
        setFeedbackStatus('pending')
    }

    const onResetFeedback = () => {
        setFeedbackStatus(null);
        setFeedback(null)
    }

    const handleSubmitFeedback = async () => {
        if (latestMessageId && sessionId && feedback) {
            try {
                await dispatch(sendFeedback({ userId: user.id, sessionId, messageId: latestMessageId, body: feedback }))
                setFeedbackStatus('success');
            } catch (err) {
                console.log(err);
            }

            setTimeout(() => {
                onResetFeedback()
            }, 1500);
        }
    }

    useEffect(() => {
        if (chatContainerRef.current && streamedText) {
            chatContainerRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
            });
        }
    }, [streamedText, sessionId, messages]);

    useEffect(() => {
        const handleLoad = () => {
            if (lastSelectedSessionId) {
                dispatch(getUserSession({ userId: user.id, sessionId: lastSelectedSessionId }))
            } else if (!!sessionsLength) {
                dispatch(getUserLatestSession(user.id));
            }
        };

        if (document.readyState === 'complete') {
            handleLoad();
        } else {
            window.addEventListener('load', handleLoad);
        }

        return () => {
            window.removeEventListener('load', handleLoad);
        };
    }, []);

    useEffect(() => {
        setMessageSidePanel({ open: false })
        setQuestion('');
    }, [sessionId, currentSessionLoading])

    useEffect(() => {
        setIsSessionSwitchDisabled(isLoading || !!messageSidePanel.streamingId)
    }, [isLoading, messageSidePanel.streamingId])

    return (
        <Box
            id="grid-container"
            ref={gridContainerRef}
            sx={{
                width: '100%',
                display: 'grid',
                gridTemplateColumns: messageSidePanel.open ? `${leftWidth}% auto 1fr` : '1fr'
            }}>
            {
                currentSessionLoading ? <Flexbox justify fullWidth fullHeight align className={classes('p-4')}><Loader /></Flexbox> :
                    <>
                        <Flexbox className={classes('messengerContainer', { 'emptyState': !messages.length })} fullWidth vertical ref={chatContainerRef}>
                            {messages.length > 0 && <Flexbox className={classes('messagesContainer')} fullWidth>
                                {
                                    (!!assistantStatus || isLoading) && (
                                        <Flexbox className={classes('messageContainer', { fromAI: streamedText?.length })}>
                                            <Flexbox className={classes('logoContainer')}>
                                                <Logo />
                                            </Flexbox>
                                            <Flexbox>
                                                {streamedText?.length ? (
                                                    <Flexbox
                                                        fullWidth
                                                        vertical
                                                    >
                                                        <Markdown text={streamedText?.replaceAll('```html', '')?.replaceAll('```', '')} />
                                                    </Flexbox>
                                                ) : (
                                                    <Flexbox className={classes('gap-1')}>
                                                        {assistantStatus &&
                                                            <Flexbox align className={classes('assistantStatus')}>
                                                                <CustomTyphography className={classes('assistantStatus-message')}>{assistantStatus}</CustomTyphography>
                                                            </Flexbox>
                                                        }
                                                        <Flexbox align className={classes('dotFlashingContainer')}>
                                                            <Flexbox className={classes('dotFlashing')} />
                                                        </Flexbox>
                                                    </Flexbox>
                                                )}
                                            </Flexbox>
                                        </Flexbox>
                                    )
                                }

                                {messages.map((message: Message, index) => (
                                    <AMAMessage
                                        key={index}
                                        message={message}
                                        showFeedbackConversation={message.from === 'assistant' && message.text.type !== AIAnswerTypes.PRD_PREVIEW_STREAM && message.text.type !== AIAnswerTypes.MOCKUP && index === 0 && !!feedbackStatus}
                                        feedbackStatus={feedbackStatus}
                                        handleRefine={() => handleRefineSection(message as AssistantMessage)}
                                        handleConfirmRefinedPRD={handleConfirmRefinedPRD}
                                        handleSendFeedback={handleSendFeedback}
                                        closeFeedbackConversation={() => setFeedbackStatus(null)}
                                        prdTitle={(message.from === 'assistant' && (message.text.type === AIAnswerTypes.PRD_PREVIEW || message.text.type === AIAnswerTypes.PRD_PREVIEW_STREAM)) ? message.text.content?.title : undefined}
                                        onGetKBSource={onGetKBSource}
                                        handlePRDSectionPreview={handleMessagePreview}
                                        currentSection={isPRDPreviewSidePanel(messageSidePanel) ? messageSidePanel.currentSection : undefined}
                                        mockupStreaming={isMockupSidePanel(messageSidePanel) && message.id === messageSidePanel.streamingId}
                                        messageSidePanelType={messageSidePanel.messageType}
                                        messageSidePanelId={messageSidePanel.id}
                                        handleMessagePreview={() => handleMessagePreview(message as AssistantMessage)}
                                    />
                                )
                                )}
                            </Flexbox>
                            }

                            <Flexbox align className={classes('newMessageContainer', { 'emptyState': !messages.length })}>
                                <Flexbox className={classes('gap-1')}>
                                    {!!sessionsLength && <Tooltip title={`${openHistorySidebar ? 'Close' : 'Open'} Chat History`}>
                                        <IconButton
                                            className={classes('action-btn')}
                                            onClick={onToggleHistorySidebar}
                                        >
                                            {openHistorySidebar ? <CloseIcon /> : <MenuOpenIcon />}
                                        </IconButton>
                                    </Tooltip>}
                                    <Tooltip title={'New Chat'}>
                                        <IconButton
                                            className={classes('action-btn')}
                                            onClick={onStartNewSession}
                                            disabled={isLoading || !!messageSidePanel.streamingId}
                                        >
                                            <ChatIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Flexbox>

                                <Flexbox vertical fullWidth className={classes({ 'gap-4': !messages.length })}>
                                    {messages.length === 0 && <Flexbox align justify className={classes('welcomeMessage')}>How can I assist you today?</Flexbox>}
                                    <TextArea
                                        ref={textAreaRef}
                                        value={question}
                                        onKeyDown={onKeyPress}
                                        onChange={e => {
                                            setQuestion(e.target.value);
                                        }}
                                        placeholder="Ask here"
                                        className={classes('textArea')}
                                        fullWidth
                                        endAdornment={
                                            <SendIcon
                                                className={classes('sendIcon', { 'disabled': !question.trim() })}
                                                onClick={() => {
                                                    if (!!question.trim()) {
                                                        onSendMessage()
                                                    }
                                                }}
                                            />
                                        }
                                        autoFocus
                                        disabled={
                                            isLoading || !!messageSidePanel.streamingId
                                        }
                                        maxRows={6}
                                    />
                                    <Flexbox align justify wrap className={classes('gap-1')}>
                                        {messages.length === 0 && chatActionButtons.map((button, index) => (
                                            <CommonButton
                                                className={classes('chatActionButton')}
                                                buttonSize="small"
                                                buttonType='shadow'
                                                key={index}
                                                onClick={() => {
                                                    if (textAreaRef.current) {
                                                        textAreaRef.current.focus();
                                                        textAreaRef.current.setSelectionRange(0, 0);
                                                    }

                                                    const finalMessage = button.message;
                                                    let currentLength = 0;

                                                    const animateText = () => {
                                                        currentLength++;
                                                        setQuestion(finalMessage.slice(0, currentLength));

                                                        if (currentLength < finalMessage.length) {
                                                            setTimeout(animateText, 8);
                                                        } else {
                                                            if (textAreaRef.current) {
                                                                textAreaRef.current.setSelectionRange(finalMessage.length, finalMessage.length);
                                                            }
                                                        }
                                                    };

                                                    animateText();
                                                }}
                                            >
                                                {button.label}
                                            </CommonButton>
                                        ))}
                                    </Flexbox>
                                </Flexbox>
                            </Flexbox>
                        </Flexbox>

                        {
                            messageSidePanel.open && <ResizableDivider containerRef={gridContainerRef} setLeftWidth={setLeftWidth} />
                        }

                        {
                            messageSidePanel.open && (
                                <MessageSidePanel
                                    title={isMockupSidePanel(messageSidePanel) ? 'Mockup Preview' : messageSidePanel.content?.title ? messageSidePanel.content?.title : 'PRD Preview'}
                                    onClose={() => setMessageSidePanel({ open: false })}
                                >
                                    {isMockupSidePanel(messageSidePanel) ? <MockupPanel
                                        content={messageSidePanel.content}
                                        streaming={messageSidePanel.streamingId === messageSidePanel.id}
                                        setAiRefineDialog={setAiRefineDialog}
                                    /> : isPRDPreviewSidePanel(messageSidePanel) ? <PRDPreviewPanel
                                        content={messageSidePanel.content}
                                        currentSection={messageSidePanel.currentSection}
                                        setAiRefineDialog={setAiRefineDialog}
                                        messageType={messageSidePanel.messageType}
                                        manual={messageSidePanel.manual}
                                    /> : <></>}
                                </MessageSidePanel>
                            )
                        }

                        {
                            feedback && <FeedbackDialog
                                pendingStatus={feedbackStatus === 'pending'}
                                handleSubmitFeedback={handleSubmitFeedback}
                                feedback={feedback}
                                handleChangeFeedback={e => setFeedback({ type: feedback.type, content: e.target.value })}
                            />
                        }
                        {
                            aiRefineDialog.open && <AIRefineDialog
                                open={aiRefineDialog.open}
                                onClose={() => setAiRefineDialog({ open: false })}
                                onConfirm={() => onSendMessage(aiRefineDialog.type)}
                                title={`Refining the ${capitalize(aiRefineDialog?.field || '')}`}
                                refinedContent={refinedContent}
                                setRefinedContent={setRefinedContent}
                            />
                        }

                        <Snackbar open={error} onClose={() => setError(false)} type="error">
                            <Flexbox>{errorMessage}</Flexbox>
                        </Snackbar>
                    </>
            }
        </Box>
    )
}

export default Session