import { CommonButton, CustomTyphography, Flexbox } from 'components'
import { CodeIcon, ContentCopyIcon, DownloadIcon, Stars, VisibilityIcon } from 'components/icons'
import { FC, useEffect, useRef, useState } from 'react'
import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import html2canvas from 'html2canvas';
import { formatHTML } from 'utils/string';

const classes = classNames.bind(styles);

const IFRAME_LOAD_DELAY = 100;
const DEFAULT_SCALE = 1;
const FLOWCHART_SCALE = 4;

interface MockupPanelProps {
    content?: string,
    setAiRefineDialog: (arg: {
        open: boolean,
        field: string,
        type: 'prd' | 'mockup',
        content?: string
    }) => void,
    streaming?: boolean
}

const MockupPanel: FC<MockupPanelProps> = ({ content, setAiRefineDialog, streaming }) => {
    const mockupRef = useRef<HTMLDivElement>(null);
    const iframeRef = useRef<HTMLIFrameElement>(null);

    const [isCopied, setIsCopied] = useState(false);
    const [isPreview, setIsPreview] = useState<boolean>(true);

    const openAiRefineDialog = () => {
        setAiRefineDialog({
            open: true,
            field: 'mockup',
            type: 'mockup',
            content
        })
    }

    const handleCopyCode = async () => {
        if (!!content) {
            try {
                await navigator.clipboard.writeText(content);
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 1000);
            } catch (err) {
                console.error('Failed to copy code: ', err);
            }
        }
    };

    const prepareIframeCanvas = async (content: string): Promise<HTMLCanvasElement> => {
        let offscreenIframe: HTMLIFrameElement | null = null;

        try {
            offscreenIframe = document.createElement('iframe');
            offscreenIframe.style.position = 'absolute';
            offscreenIframe.style.top = '-9999px';
            offscreenIframe.style.left = '-9999px';
            offscreenIframe.srcdoc = content;
            offscreenIframe.style.width = '100%';
            offscreenIframe.style.height = '100%';
            document.body.appendChild(offscreenIframe);

            await new Promise<void>((resolve, reject) => {
                if (!offscreenIframe) { return reject(new Error('Iframe not initialized')); }

                offscreenIframe.onload = async () => {
                    await new Promise(resolve => setTimeout(resolve, IFRAME_LOAD_DELAY));
                    resolve();
                };
                offscreenIframe.onerror = () => reject(new Error('Iframe failed to load'));
            });

            const iframeDocument = offscreenIframe.contentDocument || offscreenIframe.contentWindow?.document;

            if (!iframeDocument) {
                throw new Error('Failed to access the iframe content');
            }

            offscreenIframe.style.height = `${iframeDocument.body.scrollHeight}px`;
            offscreenIframe.style.width = `${iframeDocument.body.scrollWidth}px`;

            const zoomControls = iframeDocument.querySelector<HTMLElement>('#zoomControls');
            const flowchart = iframeDocument.querySelector<HTMLElement>('#flowchart');

            if (zoomControls) {
                zoomControls.style.display = 'none';
            }

            if (flowchart) {
                flowchart.style.backgroundColor = '#fff';
            }

            const scale = flowchart ? FLOWCHART_SCALE : DEFAULT_SCALE;

            const canvas = await html2canvas(iframeDocument.body, {
                useCORS: true,
                scale,
                logging: false,
                allowTaint: true,
                imageTimeout: 15000,
            });

            if (zoomControls) {
                zoomControls.style.display = '';
            }

            return canvas;
        } finally {
            // Clean up iframe in all cases
            if (offscreenIframe && offscreenIframe.parentNode) {
                offscreenIframe.parentNode.removeChild(offscreenIframe);
            }
        }
    };

    const handleCopyImage = async () => {
        if (content) {
            try {
                const canvas = await prepareIframeCanvas(content);
                const imageURL = canvas.toDataURL('image/png');
                const blob = await fetch(imageURL).then((res) => res.blob());

                await navigator.clipboard.write([
                    new ClipboardItem({
                        'image/png': blob,
                    }),
                ]);

                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 1000)
            } catch (error) {
                console.error('Failed to copy iframe content as image:', error);
            }
        }
    };

    const downloadMockupAsImage = async () => {
        if (content) {
            try {
                const canvas = await prepareIframeCanvas(content);
                const imageURL = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.href = imageURL;
                link.download = 'mockup.png';
                link.click();
            } catch (error) {
                console.error('Failed to download iframe content as image:', error);
            }
        }
    };

    useEffect(() => {
        if (mockupRef.current && streaming) {
            requestAnimationFrame(() => {
                const container = mockupRef.current;
                if (container) {
                    container.scrollTop = container.scrollHeight;
                }
            });
        }
    }, [streaming, content]);

    return (
        <Flexbox vertical className={classes('sidePanel-mainContent')} ref={mockupRef}>
            {!streaming && <Flexbox wrap className={classes('gap-2', 'actions')}>
                <CommonButton
                    buttonType='shadow'
                    data-copy='false'
                    buttonSize='small'
                    className={classes('smallIcon')}
                    onClick={downloadMockupAsImage}
                >
                    <DownloadIcon />
                    Download
                </CommonButton>
                <CommonButton
                    buttonType='shadow'
                    data-copy='false'
                    buttonSize='small'
                    className={classes('smallIcon')}
                    onClick={isPreview ? handleCopyImage : handleCopyCode}
                >
                    <ContentCopyIcon />
                    {isCopied ? 'Copied!' : isPreview ? 'Copy' : 'Copy code'}
                </CommonButton>
                <CommonButton
                    buttonType='shadow'
                    data-copy='false'
                    buttonSize='small'
                    className={classes('smallIcon')}
                    onClick={() => setIsPreview(prev => !prev)}
                >
                    {isPreview ? <Flexbox justify align className={classes('gap-2')}><CodeIcon />Show Code</Flexbox> : <Flexbox justify align className={classes('gap-2')}><VisibilityIcon />Preview</Flexbox>}
                </CommonButton>
            </Flexbox>}
            <Flexbox fullWidth vertical className={classes('gap-4', 'flex-1')}>
                {content && (
                    (streaming || !isPreview) ? (
                        <div>
                            <pre>{content.includes('<!DOCTYPE html>') ? content : formatHTML(content)}</pre>
                        </div>
                    ) : (
                        <Flexbox vertical fullHeight className={classes('gap-4')}>
                            <Flexbox align
                                className={classes('cursor-pointer', 'gap-1', 'refine-btn')}
                                onClick={() => openAiRefineDialog()}
                                data-copy='false'
                            >
                                <Stars />
                                <CustomTyphography>AI Refine</CustomTyphography>
                            </Flexbox>
                            <iframe
                                ref={iframeRef}
                                srcDoc={content.replace(/(<a\b[^>]*\bhref=["'])#(["'][^>]*>)/gi, '$1javascript:void(0);$2')}
                                style={{ flex: 1, border: 'none' }}
                            />
                        </Flexbox>
                    )
                )}
            </Flexbox>
        </Flexbox>
    )
}

export default MockupPanel