import { Box, Button, CustomTyphography, Flexbox, IconButton } from 'components'
import { FC } from 'react'
import classNames from 'classnames/bind';
import styles from '../../assistant.module.scss';
import UserLogo from 'common/UserLogo';
import { CloseIcon, Logo, ThumbDownOffAltIcon, ThumbUpOffAltIcon } from 'components/icons';
import { AIAnswerTypes, AMAReferenceTypes, AssistantMessage, Message } from 'utils/types';
import Markdown from 'components/Markdown';
import SidePanelDrawer from 'components/SidePanelDrawer';
import { useSelector } from 'react-redux';
import { referencesDataLoadingSelector, referencesDataSelector } from 'store/knowledgeBase-slice';
import { isHtmlLike, isMarkdown } from 'utils/string';
import PRDSectionButtons from './PRDSectionButtons';
import WebPageReferencesPanel from './WebPageReferencesPanel';
import KnowledgeReferencesPanel from './KnowledgeReferencesPanel';

const classes = classNames.bind(styles);

interface AMAMessageProps {
    message: Message
    showFeedbackConversation: boolean
    feedbackStatus: 'initial' | 'pending' | 'success' | null
    handleRefine: () => void
    handleConfirmRefinedPRD: () => void
    handleSendFeedback: (feedbackType: 'positive' | 'negative') => void,
    closeFeedbackConversation: () => void
    prdTitle?: string
    onGetKBSource: (kbId: number, kbItemIds: number[]) => Promise<void>,
    handlePRDSectionPreview: (message: AssistantMessage, key: string) => void
    currentSection?: string
    mockupStreaming?: boolean
    messageSidePanelType?: AIAnswerTypes.PRD_PREVIEW | AIAnswerTypes.PRD_PREVIEW_STREAM | AIAnswerTypes.MOCKUP,
    messageSidePanelId?: string | number
    handleMessagePreview: () => void
}

const AMAMessage: FC<AMAMessageProps> = ({
    message,
    showFeedbackConversation,
    feedbackStatus,
    handleRefine,
    handleConfirmRefinedPRD,
    handleSendFeedback,
    closeFeedbackConversation,
    prdTitle,
    onGetKBSource,
    handlePRDSectionPreview,
    currentSection,
    mockupStreaming,
    messageSidePanelType,
    messageSidePanelId,
    handleMessagePreview
}) => {
    const referencesData = useSelector(referencesDataSelector)
    const referencesDataLoading = useSelector(referencesDataLoadingSelector)

    return (
        <Flexbox vertical fullWidth>
            <Flexbox
                className={classes('messageContainer', { fromAI: message.from === 'assistant' })}
            >
                <Flexbox className={classes('logoContainer')}>
                    {message.from === 'user' ? <UserLogo /> : <Logo />}
                </Flexbox>
                {message.from === 'user' ? (
                    <Flexbox className={classes('message')}>{message.text}</Flexbox>
                ) : (
                    <Flexbox
                        fullWidth
                        vertical
                    >
                        {message.text.type === AIAnswerTypes.TEXT && typeof message.text.content === 'string' ? (
                            isHtmlLike(message.text.content) ? (
                                <iframe
                                    srcDoc={`
                                        ${message.text.content?.replace(/(<a\b[^>]*\bhref=["'])#(["'][^>]*>)/gi, '$1javascript:void(0);$2')}
                                        <script>
                                            window.addEventListener('load', function() {
                                                const height = document.body.scrollHeight + 35;
                                                window.parent.postMessage({ height }, '*');
                                            });
                                        </script>
                                        <style>
                                            body { 
                                                margin: 0; 
                                                padding: 0;
                                            }
                                        </style>
                                    `}
                                    style={{
                                        maxHeight: '50vh',
                                        border: 'none',
                                        width: '100%'
                                    }}
                                    ref={(iframe) => {
                                        if (iframe) {
                                            window.addEventListener('message', (event) => {
                                                const height = Math.min(event.data.height, window.innerHeight * 0.5);
                                                iframe.style.height = `${height}px`;
                                            });
                                        }
                                    }}
                                />
                            ) : <Markdown text={message.text.content?.replaceAll('```html', '')?.replaceAll('```', '')} />
                        ) : message.text.type === AIAnswerTypes.PRD_PREVIEW_STREAM ? (
                            <Flexbox vertical className={classes('gap-4')}>
                                <Flexbox>Preparing the PRD</Flexbox>
                                <PRDSectionButtons
                                    message={message}
                                    handlePRDSectionPreview={handlePRDSectionPreview}
                                    prdPreviewId={messageSidePanelId}
                                    currentSection={currentSection}
                                />
                            </Flexbox>
                        ) : message.text.type === AIAnswerTypes.PRD_PREVIEW ? (
                            <Flexbox vertical className={classes('gap-4')}>
                                <PRDSectionButtons
                                    message={message}
                                    handlePRDSectionPreview={handlePRDSectionPreview}
                                    prdPreviewId={messageSidePanelId}
                                    currentSection={currentSection}
                                />
                                <Flexbox vertical className={classes('gap-2')}>
                                    <Flexbox>Product Requirement Documents is ready!</Flexbox>
                                    {!!prdTitle && (isMarkdown(prdTitle) ? <Markdown text={prdTitle?.replaceAll('```html', '')?.replaceAll('```', '')} /> : <Flexbox className={classes('prdTitle')}>{prdTitle}</Flexbox>)}
                                </Flexbox>
                                <Button
                                    sx={{
                                        width: '175px',
                                        backgroundColor: messageSidePanelType === AIAnswerTypes.PRD_PREVIEW && messageSidePanelId && message.id === messageSidePanelId && !currentSection ? '#003539' : '#025B62'
                                    }}
                                    variant='contained'
                                    onClick={handleMessagePreview}
                                >
                                    Click to review
                                </Button>
                            </Flexbox>
                        ) : message.text.type === AIAnswerTypes.MOCKUP ? (
                            <Flexbox vertical className={classes('gap-4')}>
                                {mockupStreaming ? <Flexbox>Preparing the Mockup...</Flexbox> : <Flexbox>Mockup is ready!</Flexbox>}
                                <Button
                                    sx={{
                                        width: '225px',
                                        backgroundColor: messageSidePanelType === AIAnswerTypes.MOCKUP && messageSidePanelId && message.id === messageSidePanelId ? '#003539' : '#025B62'
                                    }}
                                    variant='contained'
                                    onClick={handleMessagePreview}
                                >
                                    Click to open component
                                </Button>
                            </Flexbox>
                        ) : <Flexbox></Flexbox>
                        }
                        {!!message?.references?.length && (
                            <Flexbox vertical className={classes('gap-2', 'sources-container')}>
                                <CustomTyphography className={classes('font-500', 'sources-title')}>Sources:</CustomTyphography>
                                <Flexbox wrap align className={classes('gap-1')}>
                                    {message.references.map((ref, refIndex) => (
                                        <SidePanelDrawer
                                            key={ref.type === AMAReferenceTypes.KNOWLEDGE ? ref.knowledge_id : refIndex}
                                            actionElement={
                                                (props: any) => (
                                                    <Flexbox align {...props}>
                                                        <Box
                                                            className={classes('sources-refSidePanel')}
                                                            onClick={() => {
                                                                if (ref.type === AMAReferenceTypes.KNOWLEDGE) {
                                                                    onGetKBSource(ref.knowledge_id, ref.items.map(el => el.item_id))
                                                                }
                                                            }}
                                                        >
                                                            {ref.title}
                                                        </Box>
                                                    </Flexbox>
                                                )
                                            }
                                        >
                                            {ref.type === AMAReferenceTypes.WEBPAGE ? <WebPageReferencesPanel
                                                title={ref.title}
                                                data={
                                                    {
                                                        description: ref.description,
                                                        summarized_content: ref.summarized_content,
                                                        url: ref.url,
                                                    }
                                                }
                                            /> : <KnowledgeReferencesPanel
                                                title={'Sources'}
                                                loading={referencesDataLoading}
                                                data={referencesData.map(ref => ({ ...ref.data, createdDate: ref.createdDate, lastModifiedDate: ref.lastModifiedDate }))}
                                            />}
                                        </SidePanelDrawer>
                                    ))}
                                </Flexbox>
                            </Flexbox>
                        )}
                        {
                            !!message.text.refinement && (

                                <Flexbox align className={classes('gap-2', 'mt-2')}>
                                    <Button
                                        variant='contained'
                                        onClick={handleConfirmRefinedPRD}
                                    >
                                        Looks good
                                    </Button>
                                    <Button
                                        variant='outlined'
                                        onClick={handleRefine}
                                    >
                                        Lets refine further
                                    </Button>
                                </Flexbox>
                            )
                        }
                    </Flexbox>
                )}
            </Flexbox>
            {showFeedbackConversation && (
                <Flexbox justify align className={classes('userFeedbackContainer', 'gap-2')}>
                    {feedbackStatus === 'success' ? <CustomTyphography>Thank you for your feedback!</CustomTyphography> :
                        <>
                            <CustomTyphography>Is this conversation helpful so far?</CustomTyphography>
                            <Flexbox align fullHeight className={classes('rightBorder', 'gap-1', 'pr-2')}>
                                <IconButton onClick={() => handleSendFeedback('positive')}>
                                    <ThumbUpOffAltIcon />
                                </IconButton>
                                <IconButton onClick={() => handleSendFeedback('negative')}>
                                    <ThumbDownOffAltIcon />
                                </IconButton>
                            </Flexbox>
                            <IconButton onClick={closeFeedbackConversation}>
                                <CloseIcon />
                            </IconButton>
                        </>
                    }
                </Flexbox>
            )}
        </Flexbox>
    )
}

export default AMAMessage