import { createSlice } from '@reduxjs/toolkit'
import { RoadmapMetrics, RoadmapMetricsPayload, RiskCategoryDistribution, StatusTypes, InitiativeStatus, Initiative } from 'utils/types'
import { roadmapMetricsApi } from './roadmapMetrics-api';
import { RootState } from 'store';
import { getQuarterEndDate, getQuarterStartDate } from 'utils/date';

interface RoadmapMetricsState {
    roadmapMetrics: RoadmapMetrics,
    startDate: Date | null,
    endDate: Date | null,
    calendarSelect?: string,
    executionStartDate: Date | null,
    plannedInitiatives: Initiative[],
    notPlannedCarryoverInitiatives: Initiative[],
    shouldUpdate: boolean,
    plannedInitiativesGridLayout?: Record<string, any>,
    notPlannedCarryoverInitiativesGridLayout?: Record<string, any>,
    plannedInitiativesSort?: { order: 'asc' | 'desc', orderBy: string },
    notPlannedCarryoverInitiativesSort?: { order: 'asc' | 'desc', orderBy: string },
}

const initialState: RoadmapMetricsState = {
    roadmapMetrics: {
        initiativeMetrics: {},
        teamMetrics: {},
    },
    startDate: getQuarterStartDate(new Date()),
    endDate: getQuarterEndDate(new Date()),
    executionStartDate: null,
    plannedInitiatives: [],
    notPlannedCarryoverInitiatives: [],
    shouldUpdate: false,
}

interface RiskChartConfig {
    status: StatusTypes | 'NotCalculated';
    fill: string;
}

export const RISK_MAPPING: Record<keyof RiskCategoryDistribution, RiskChartConfig> = {
    low: { status: StatusTypes.Low, fill: '#6EE7B7' },
    medium: { status: StatusTypes.Medium, fill: '#FCD34D' },
    high: { status: StatusTypes.High, fill: '#FC8790' },
    critical: { status: StatusTypes.Critical, fill: '#EF4444' },
    not_calculated: { status: 'NotCalculated' as const, fill: '#E5E7EB' },
};

interface StatusChartConfig {
    status: string;
    fill: string;
}

export const STATUS_MAPPING: Record<string, StatusChartConfig> = Object.entries(InitiativeStatus).reduce((acc, [displayName, enumValue]) => ({
    ...acc,
    [enumValue]: {
        status: displayName,
        fill: {
            Backlog: '#A8B5C5',
            Conception: '#F87C3D',
            Research: '#BC7AF9',
            Design: '#38B9F1',
            RequirementGathering: '#34C5B8',
            ReadyForDevelopment: '#4372E0',
            Development: '#48D174',
            InTesting: '#F0C132',
            DevelopmentDone: '#2A9D52',
            Release: '#FA9449',
            ABTesting: '#E54D4D',
            Live: '#9355F1',
            Blocked: '#D13651',
            Sunsetted: '#5E6B7E',
            Canceled: '#374151',
            AiInProgress: '#EF65AB',
            Paused: '#C66A2B'
        }[enumValue]
    }
}), {});

export const transformAndSortDistributionData = (
    distribution: Record<string, number>,
    mapping: Record<string, StatusChartConfig | RiskChartConfig>
): { risk?: string; status?: string; percentage: number; fill: string }[] => {
    if (!distribution || Object.keys(distribution).length === 0) { return []; }

    const results = Object.entries(distribution)
        .filter(([_, percentage]) => percentage !== undefined)
        .map(([key, percentage]) => {
            const config = mapping[key];
            return {
                ...(mapping === RISK_MAPPING ? { risk: config.status } : { status: config.status }),
                percentage,
                fill: config.fill
            };
        });

    return results.sort((a, b) => {
        const mappingKeys = Object.keys(mapping);
        const getKeyByValue = (item: typeof results[0]) => {
            return Object.keys(mapping).find(key =>
                mapping[key].status === ('risk' in item ? item.risk : item.status)
            ) || '';
        };

        const indexA = mappingKeys.indexOf(getKeyByValue(a));
        const indexB = mappingKeys.indexOf(getKeyByValue(b));

        return indexA - indexB;
    });
};

const roadmapMetricsSlice = createSlice({
    name: 'roadmapMetrics',
    initialState,
    reducers: {
        setStartDate: (state, { payload }: { payload: Date | null }) => {
            state.startDate = payload
        },
        setEndDate: (state, { payload }: { payload: Date | null }) => {
            state.endDate = payload
        },
        setCalendarSelect: (state, { payload }: { payload: string | undefined }) => {
            state.calendarSelect = payload
        },
        setExecutionStartDate: (state, { payload }: { payload: Date | null }) => {
            state.executionStartDate = payload
        },
        setShouldUpdate: (state, { payload }: { payload: boolean }) => {
            state.shouldUpdate = payload
        },
        setPlannedInitiativesGridLayout: (state, { payload }: { payload: Record<string, any> }) => {
            state.plannedInitiativesGridLayout = payload
        },
        setNotPlannedCarryoverInitiativesGridLayout: (state, { payload }: { payload: Record<string, any> }) => {
            state.notPlannedCarryoverInitiativesGridLayout = payload
        },
        setPlannedInitiativesSort: (state, { payload }: { payload: { order: 'asc' | 'desc', orderBy: string } }) => {
            state.plannedInitiativesSort = payload
        },
        setNotPlannedCarryoverInitiativesSort: (state, { payload }: { payload: { order: 'asc' | 'desc', orderBy: string } }) => {
            state.notPlannedCarryoverInitiativesSort = payload
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            roadmapMetricsApi.endpoints.getRoadmapMetrics.matchFulfilled,
            (state, { payload }: { payload: RoadmapMetricsPayload }) => {
                state.roadmapMetrics = {
                    initiativeMetrics: {
                        plannedInitiatives: payload?.plannedInitiativesCount,
                        activeInitiatives: payload?.activeInitiativesCount,
                        notStartedInitiatives: payload?.notStartedInitiativesCount,
                        completedInitiatives: payload?.completedInitiativesCount,
                        plannedCarryOverInitiatives: payload?.plannedCarryOverInitiativesCount,
                        notPlannedCarryoverInitiatives: payload?.notPlannedCarryoverInitiativesCount,
                        pushedOutInitiatives: payload?.pushedOutInitiativesCount,
                        replanedInitiatives: payload?.replanedInitiativesCount,
                    },
                    teamMetrics: {
                        totalInvolvedPeople: payload?.totalInvolvedPeopleCount,
                        activePeople: payload?.activePeopleCount,
                    },
                    riskCategoryDistribution: payload?.riskCategoryDistribution,
                    statusCategoryAggregated: payload?.statusCategoryAggregated,
                    plannedInitiatives: payload?.plannedInitiatives,
                    activeInitiatives: payload?.activeInitiatives,
                    notStartedInitiatives: payload?.notStartedInitiatives,
                    completedInitiatives: payload?.completedInitiatives,
                    plannedCarryOverInitiatives: payload?.plannedCarryOverInitiatives,
                    notPlannedCarryoverInitiatives: payload?.notPlannedCarryoverInitiatives,
                    pushedOutInitiatives: payload?.pushedOutInitiatives,
                    replanedInitiatives: payload?.replanedInitiatives,
                }
            },
        )
    },
})

export const roadmapMetricsSelector = (store: RootState) => store.roadmapMetrics.roadmapMetrics;
export const startDateSelector = (store: RootState) => store.roadmapMetrics.startDate;
export const endDateSelector = (store: RootState) => store.roadmapMetrics.endDate;
export const riskCategoryDistributionSelector = (store: RootState) => store.roadmapMetrics.roadmapMetrics.riskCategoryDistribution;
export const statusCategoryDistributionSelector = (store: RootState) => store.roadmapMetrics.roadmapMetrics.statusCategoryAggregated;
export const initiativeMetricsSelector = (store: RootState) => store.roadmapMetrics.roadmapMetrics.initiativeMetrics;
export const teamMetricsSelector = (store: RootState) => store.roadmapMetrics.roadmapMetrics.teamMetrics;
export const calendarSelectSelector = (store: RootState) => store.roadmapMetrics.calendarSelect;
export const executionStartDateSelector = (store: RootState) => store.roadmapMetrics.executionStartDate;
export const plannedInitiativesSelector = (store: RootState) => store.roadmapMetrics.roadmapMetrics.plannedInitiatives;
export const notPlannedCarryoverInitiativesSelector = (store: RootState) => store.roadmapMetrics.roadmapMetrics.notPlannedCarryoverInitiatives;
export const shouldUpdateSelector = (store: RootState) => store.roadmapMetrics.shouldUpdate;
export const plannedInitiativesGridLayoutSelector = (store: RootState) => store.roadmapMetrics.plannedInitiativesGridLayout;
export const notPlannedCarryoverInitiativesGridLayoutSelector = (store: RootState) => store.roadmapMetrics.notPlannedCarryoverInitiativesGridLayout;
export const plannedInitiativesSortSelector = (store: RootState) => store.roadmapMetrics.plannedInitiativesSort;
export const notPlannedCarryoverInitiativesSortSelector = (store: RootState) => store.roadmapMetrics.notPlannedCarryoverInitiativesSort;

export const {
    setStartDate,
    setEndDate,
    setCalendarSelect,
    setExecutionStartDate,
    setShouldUpdate,
    setPlannedInitiativesGridLayout,
    setNotPlannedCarryoverInitiativesGridLayout,
    setPlannedInitiativesSort,
    setNotPlannedCarryoverInitiativesSort,
} = roadmapMetricsSlice.actions

export default roadmapMetricsSlice