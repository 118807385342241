import { Box, CommonButton, CustomTyphography, Flexbox } from 'components'
import { ContentCopyIcon, Stars } from 'components/icons'
import PRDPreviewItem from '../PRDPreviewItem'
import { FC, useEffect, useRef, useState } from 'react'
import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import { AIAnswerTypes, PRDMainType } from 'utils/types';
import { returnMainContent } from '../..';

const classes = classNames.bind(styles);

interface PRDPreviewPanelProps {
    messageType?: AIAnswerTypes,
    content?: PRDMainType,
    currentSection?: string,
    setAiRefineDialog: (arg: {
        open: boolean,
        field: string,
        type: 'prd',
        content: string
    }) => void,
    manual?: boolean
}

const PRDPreviewPanel: FC<PRDPreviewPanelProps> = ({ content, setAiRefineDialog, messageType, currentSection, manual }) => {
    const prdPreviewRef = useRef<HTMLDivElement>(null);
    const [isCopied, setIsCopied] = useState(false)

    const openAiRefineDialog = (key: string) => {
        setAiRefineDialog({
            open: true,
            field: key,
            type: 'prd',
            content: content && content[key] && { [key]: content[key] }
        })
    }

    const handleCopyPRD = () => {
        const contentElement = document.querySelector('#copy_content');

        if (!contentElement) {
            console.error('Content element not found');
            return;
        }

        const elementsToExclude = contentElement.querySelectorAll<HTMLElement>('[data-copy="false"]');
        elementsToExclude.forEach(el => el.style.display = 'none');

        const range = document.createRange();
        range.selectNodeContents(contentElement);
        const selection = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
            selection.addRange(range);
            try {
                document.execCommand('copy');
                setIsCopied(true)
                setTimeout(() => setIsCopied(false), 1000)
            } catch (err) {
                console.error('Failed to copy text: ', err);
            }

            selection.removeAllRanges();
        }

        elementsToExclude.forEach(el => el.style.display = '');
    };

    useEffect(() => {
        if (prdPreviewRef.current && !manual) {
            requestAnimationFrame(() => {
                const container = prdPreviewRef.current;
                if (!container) { return };

                if (messageType === AIAnswerTypes.PRD_PREVIEW_STREAM) {
                    container.scrollTop = container.scrollHeight;
                } else if (messageType === AIAnswerTypes.PRD_PREVIEW && !currentSection) {
                    container.scrollTop = 0;
                }
            });
        }
    }, [messageType, currentSection, content, manual]);

    return (
        <Box className={classes('sidePanel-mainContent')} ref={prdPreviewRef}>
            {
                messageType !== AIAnswerTypes.PRD_PREVIEW_STREAM &&
                <Flexbox wrap className={classes('gap-2', 'actions')}>
                    <CommonButton
                        buttonType='shadow'
                        data-copy='false'
                        buttonSize='small'
                        className={classes('smallIcon')}
                        onClick={handleCopyPRD}
                    >
                        {isCopied ? 'Copied!' : currentSection ? `Copy ${currentSection}` : 'Copy PRD'}
                        <ContentCopyIcon />
                    </CommonButton>
                </Flexbox>
            }
            <Flexbox fullWidth vertical className={classes('gap-4')}>
                {content && Object.entries(returnMainContent(content, currentSection)).map(([key, value]) => (
                    <Flexbox key={key} vertical className={classes('gap-2')}>
                        <Flexbox align justifyBetween>
                            <Flexbox className={classes('prdFieldLabel')}>{key}</Flexbox>
                            {
                                messageType === AIAnswerTypes.PRD_PREVIEW && (
                                    <Flexbox align
                                        className={classes('cursor-pointer', 'gap-1', 'refine-btn')}
                                        onClick={() => openAiRefineDialog(key)}
                                        data-copy='false'
                                    >
                                        <Stars />
                                        <CustomTyphography>AI Refine</CustomTyphography>
                                    </Flexbox>
                                )
                            }
                        </Flexbox>
                        {
                            value !== null && <PRDPreviewItem value={value} />
                        }
                    </Flexbox>
                ))}
            </Flexbox>
        </Box>
    )
}

export default PRDPreviewPanel