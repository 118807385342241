import { useEffect, useState } from 'react';
import { Flexbox, Grid, Loader } from 'components'
import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import Header from './components/Header';
import Overview from './components/Overview';
import StatisticPieChart from './components/StatisticPieChart';
import { useDispatch, useSelector } from 'react-redux';
import { useWorkspaceId } from 'utils/hooks';
import { useLazyGetRoadmapMetricsQuery, } from 'store/roadmapMetrics-api';
import {
    endDateSelector,
    riskCategoryDistributionSelector,
    startDateSelector,
    statusCategoryDistributionSelector,
    RISK_MAPPING,
    STATUS_MAPPING,
    transformAndSortDistributionData,
    executionStartDateSelector,
    setExecutionStartDate,
    shouldUpdateSelector,
    setShouldUpdate,
    setPlannedInitiativesGridLayout,
    setNotPlannedCarryoverInitiativesGridLayout,
    setNotPlannedCarryoverInitiativesSort,
    setPlannedInitiativesSort,
} from 'store/roadmapMetrics-slice';
import { formatDate } from 'utils/date';
import { Preferences, PreferencesKeys } from 'utils/types';
import { getPreferences, updatePreferences } from 'common/preferences/index.api';
import { useSearchParams } from 'react-router-dom';
import { setStartDate, setEndDate } from 'store/roadmapMetrics-slice';
import InitiativesTable from './components/InitiativesTable';

const classes = classNames.bind(styles);

export enum FilterKeys {
    startDate = 'startDate',
    endDate = 'endDate',
    executionStartDate = 'executionStartDate',
    plannedInitiativesGridLayout = 'plannedInitiativesGridLayout',
    notPlannedCarryoverInitiativesGridLayout = 'notPlannedCarryoverInitiativesGridLayout',
    plannedInitiativesSort = 'plannedInitiativesSort',
    notPlannedCarryoverInitiativesSort = 'notPlannedCarryoverInitiativesSort'
}

interface PreferenceData {
    [FilterKeys.startDate]?: string;
    [FilterKeys.endDate]?: string;
    [FilterKeys.executionStartDate]?: string;
    [FilterKeys.plannedInitiativesGridLayout]?: Record<string, any>;
    [FilterKeys.notPlannedCarryoverInitiativesGridLayout]?: Record<string, any>;
    [FilterKeys.plannedInitiativesSort]?: { order: 'asc' | 'desc', orderBy: string };
    [FilterKeys.notPlannedCarryoverInitiativesSort]?: { order: 'asc' | 'desc', orderBy: string };
}

const METRIC_FORMAT = 'YYYY-MM-DD';

const filterValidEntries = (obj: Record<string, any>) =>
    Object.entries(obj).reduce((acc, [key, value]) => {
        if (value !== null && value !== undefined) {
            acc[key] = value;
        }
        return acc;
    }, {} as Record<string, string>);

const RoadmapMetrics = () => {
    const workspaceId = useWorkspaceId();
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();

    const [getRoadmapMetrics, { isLoading: roadmapMetricsLoading, isFetching: roadmapMetricsFetching }] = useLazyGetRoadmapMetricsQuery();

    const startDate = useSelector(startDateSelector)
    const endDate = useSelector(endDateSelector)
    const executionStartDate = useSelector(executionStartDateSelector);
    const riskCategoryDistribution = useSelector(riskCategoryDistributionSelector);
    const statusCategoryDistribution = useSelector(statusCategoryDistributionSelector);
    const shouldUpdate = useSelector(shouldUpdateSelector);

    const [isLoading, setIsLoading] = useState(true);

    const loadPreferences = async () => {
        const preferences: Preferences<FilterKeys>[] = (await dispatch(getPreferences(PreferencesKeys.roadmapMetrics))) as unknown as Preferences<FilterKeys>[];

        if (preferences && preferences.length) {
            const preferenceData = preferences[0];
            const filters = ('main' in preferenceData.value ? preferenceData.value.main : preferenceData.value) as PreferenceData;

            const dispatchActions = {
                [FilterKeys.startDate]: (value: string) => dispatch(setStartDate(new Date(value))),
                [FilterKeys.endDate]: (value: string) => dispatch(setEndDate(new Date(value))),
                [FilterKeys.executionStartDate]: (value: string) => dispatch(setExecutionStartDate(new Date(value))),
                [FilterKeys.plannedInitiativesGridLayout]: (value: Record<string, any>) => dispatch(setPlannedInitiativesGridLayout(value)),
                [FilterKeys.notPlannedCarryoverInitiativesGridLayout]: (value: Record<string, any>) => dispatch(setNotPlannedCarryoverInitiativesGridLayout(value)),
                [FilterKeys.plannedInitiativesSort]: (value: { order: 'asc' | 'desc', orderBy: string }) => dispatch(setPlannedInitiativesSort(value)),
                [FilterKeys.notPlannedCarryoverInitiativesSort]: (value: { order: 'asc' | 'desc', orderBy: string }) => dispatch(setNotPlannedCarryoverInitiativesSort(value)),
            };

            if (searchParams.toString().length === 0) {
                setSearchParams(filterValidEntries({
                    startDate: filters.startDate,
                    endDate: filters.endDate,
                    executionStartDate: filters.executionStartDate
                }), { replace: true });
            }

            Object.entries(filters).forEach(([key, value]) => {
                if (value && key in dispatchActions) {
                    dispatchActions[key as FilterKeys](value);
                }
            });

            return {
                startDate: filters.startDate || startDate,
                endDate: filters.endDate || endDate,
                executionStartDate: filters.executionStartDate || executionStartDate,
            };
        }
    }

    useEffect(() => {
        const loadData = async () => {
            try {
                const filters = await loadPreferences();

                if (filters) {
                    await getRoadmapMetrics({
                        workspaceId,
                        startDate: filters.executionStartDate ? formatDate(new Date(filters.executionStartDate), METRIC_FORMAT) : filters.startDate && formatDate(new Date(filters.startDate), METRIC_FORMAT),
                        endDate: filters.endDate ? formatDate(new Date(filters.endDate), METRIC_FORMAT) : formatDate(new Date(), METRIC_FORMAT)
                    });
                }
            } catch (error) {
                console.error('Error loading roadmap metrics data:', error);
            } finally {
                setIsLoading(false);
            }
        }

        setIsLoading(true);
        loadData();
    }, [])

    useEffect(() => {
        const loadData = async () => {
            if (shouldUpdate && ((executionStartDate || startDate) && endDate)) {
                dispatch(updatePreferences(
                    {
                        [FilterKeys.startDate]: startDate,
                        [FilterKeys.endDate]: endDate,
                        [FilterKeys.executionStartDate]: executionStartDate
                    },
                    PreferencesKeys.roadmapMetrics
                ));

                setSearchParams(filterValidEntries({
                    [FilterKeys.startDate]: startDate ? startDate.toISOString() : undefined,
                    [FilterKeys.endDate]: endDate ? endDate.toISOString() : undefined,
                    [FilterKeys.executionStartDate]: executionStartDate ? executionStartDate.toISOString() : undefined
                }));

                await getRoadmapMetrics({
                    workspaceId,
                    startDate: executionStartDate ? formatDate(executionStartDate, METRIC_FORMAT) : formatDate(startDate || new Date(), METRIC_FORMAT),
                    endDate: formatDate(endDate || new Date(), METRIC_FORMAT)
                });
            }

            dispatch(setShouldUpdate(false));
        }
        loadData();
    }, [executionStartDate, startDate, endDate, shouldUpdate]);

    return (
        <Flexbox vertical fullWidth className={classes('roadmapMetricsContainer')}>
            {isLoading ? <Flexbox align justify fullHeight><Loader /></Flexbox> : (
                <Flexbox vertical fullWidth className={classes('roadmapMetrics')}>
                    <Header />
                    <Flexbox vertical className={classes('gap-3')}>
                        <Overview
                            isLoading={roadmapMetricsLoading || roadmapMetricsFetching}
                        />
                        {
                            roadmapMetricsLoading || roadmapMetricsFetching ? <Flexbox align justify fullHeight className={classes('mt-4')}><Loader /></Flexbox> : (
                                <>
                                    <Grid container spacing={3}>
                                        {statusCategoryDistribution && <Grid item lg={6} md={12} xs={12}>
                                            <StatisticPieChart
                                                dataKey="percentage"
                                                nameKey="status"
                                                data={transformAndSortDistributionData(statusCategoryDistribution, STATUS_MAPPING)}
                                                title="Project Status"
                                                description="Number of initiatives per status"
                                            />
                                        </Grid>
                                        }
                                        {
                                            riskCategoryDistribution && <Grid item lg={6} md={12} xs={12}>
                                                <StatisticPieChart
                                                    dataKey="percentage"
                                                    nameKey="risk"
                                                    data={transformAndSortDistributionData(riskCategoryDistribution, RISK_MAPPING)}
                                                    title="Risk Level"
                                                    description="Number of initiatives per risk level"
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                    <InitiativesTable />
                                </>
                            )}
                    </Flexbox>
                </Flexbox>
            )}
        </Flexbox>
    )
}

export default RoadmapMetrics