import { CustomTyphography, Flexbox, Loader } from 'components';
import classNames from 'classnames/bind';
import styles from '../styles.module.scss';
import { FC } from 'react';
import { camelCaseToNormal } from 'utils/string';
import { useSelector } from 'react-redux';
import { initiativeMetricsSelector, teamMetricsSelector } from 'store/roadmapMetrics-slice';

const classes = classNames.bind(styles);

interface OverviewProps {
    isLoading: boolean;
}

const Overview: FC<OverviewProps> = ({
    isLoading,
}) => {
    const initiativeMetrics = useSelector(initiativeMetricsSelector);
    const teamMetrics = useSelector(teamMetricsSelector);

    return (

        <Flexbox vertical className={classes('overview', 'section', 'p-5', 'gap-4')}>
            <Flexbox vertical className={classes('sectionHeader')}>
                <CustomTyphography className={classes('title')}>Overview</CustomTyphography>
                <CustomTyphography className={classes('description')}>Number of initiatives per status</CustomTyphography>
            </Flexbox>
            <Flexbox stretch className={classes('gap-2')} wrap>
                <Flexbox className={classes('contentBlock', 'p-3')}>
                    <Flexbox fullWidth vertical className={classes('gap-3')}>
                        <Flexbox className={classes('title')}>Project Portfolio</Flexbox>
                        <Flexbox vertical fullWidth className={classes('gap-1')}>
                            {initiativeMetrics && Object.entries(initiativeMetrics).map(([key, value]) => (
                                <Flexbox key={key} align justifyBetween className={classes('item')}>
                                    <Flexbox className={classes('label')}>
                                        {camelCaseToNormal(key)}
                                    </Flexbox>
                                    {isLoading ? <Loader size={20} /> : <Flexbox className={classes('value')}>{value}</Flexbox>}
                                </Flexbox>
                            ))}
                        </Flexbox>
                    </Flexbox>
                </Flexbox>

                <Flexbox className={classes('contentBlock', 'p-3')}>
                    <Flexbox fullWidth vertical className={classes('gap-3')}>
                        <Flexbox className={classes('title')}>Resources</Flexbox>
                        <Flexbox vertical fullWidth className={classes('gap-1')}>
                            {teamMetrics && Object.entries(teamMetrics).map(([key, value]) => (
                                <Flexbox key={key} align justifyBetween className={classes('item')}>
                                    <Flexbox className={classes('label')}>
                                        {camelCaseToNormal(key)}
                                    </Flexbox>
                                    {isLoading ? <Loader size={20} /> : <Flexbox className={classes('value')}>{value}</Flexbox>}
                                </Flexbox>
                            ))}
                        </Flexbox>
                    </Flexbox>
                </Flexbox>

                {/* <Flexbox className={classes('contentBlock', 'p-3')}>
                    <Flexbox fullWidth vertical className={classes('gap-3')}>
                        <Flexbox className={classes('title')}>Risk and Impact</Flexbox>
                        <Flexbox vertical fullWidth className={classes('gap-1')}>
                            <Flexbox align justifyBetween className={classes('item', [overallRisk ? overallRisk?.toLowerCase() : ''])}>
                                <Flexbox className={classes('label')}>
                                    Overall Risk
                                </Flexbox>
                                {isLoading ? <Loader size={20} /> : <Flexbox className={classes('value')}>{overallRisk || 'No Risk Calculated'}</Flexbox>}
                            </Flexbox>
                            <Flexbox align justifyBetween className={classes('item', [businessImpact ? businessImpact?.toLowerCase() : ''])}>
                                <Flexbox className={classes('label')}>
                                    Business Impact
                                </Flexbox>
                                {isLoading ? <Loader size={20} /> : <Flexbox className={classes('value')}>{businessImpact}</Flexbox>}
                            </Flexbox>
                        </Flexbox>
                    </Flexbox>
                </Flexbox> */}
            </Flexbox>
        </Flexbox>
    )
}

export default Overview